import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Img from 'gatsby-image'
import Footer from '../components/footer'

const AboutMe = ({ data }) => {
	return (
		<React.Fragment>
			<Layout>
				<SEO title="About me" keywords={[`portfolio`, `about`, `faq`, `bio`]} />
				<div className="container about">
					<div className="row">
						<div className="col-md-9 about-hero">
							<h1>{data.wordpressPage.acf.hero.title}</h1>
							<span>{data.wordpressPage.acf.hero.subtitle}</span>
						</div>
						<div className="col-lg-9">
							<div className="row">
								<div className="col-md-7 bio">
									<div
										dangerouslySetInnerHTML={{
											__html: data.wordpressPage.acf.bio,
										}}
									/>
									{data.wordpressPage.acf.links.map(link => (
										<span key={link.link.title}>{link.link.title}</span>
									))}
								</div>
								<div className="col-md-4 about-image">
									<Img
										fluid={
											data.wordpressPage.featured_media.localFile
												.childImageSharp.fluid
										}
										alt={data.wordpressPage.featured_media.alt_text}
									/>
								</div>
								<div className="col-lg-8 faq">
									<h2>faq.</h2>
									<ol>
										{data.wordpressPage.acf.faq.map(faqItem => (
											<li key={faqItem.faq_answer}>
												{faqItem.faq_question}
												<div
													dangerouslySetInnerHTML={{
														__html: faqItem.faq_answer,
													}}
												/>
											</li>
										))}
									</ol>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Layout>
			<Footer
				message={data.wordpressPage.acf.footer.message}
				link={data.wordpressPage.acf.footer.link}
			/>
		</React.Fragment>
	)
}

export default AboutMe

export const aboutQuery = graphql`
	query {
		wordpressPage(
			guid: { eq: "https://examplebackend.timacumdev.com/?page_id=27" }
		) {
			featured_media {
				alt_text
				localFile {
					childImageSharp {
						fluid(maxWidth: 580, quality: 100) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
			acf {
				footer {
					message
					link {
						title
						url
						target
					}
				}
				hero {
					title
					subtitle
				}
				bio
				links {
					link {
						title
						url
						target
					}
				}
				faq {
					faq_question
					faq_answer
				}
			}
		}
	}
`
